.ui.card:first-child {
  margin-top: 1em;
}

.MuiDataGrid-columnHeaderTitleContainerContent > *{
  font-weight: bolder!important;
}

.singleJobRelatedContainer{
          }

.RelatedJobsContainer {
          width: 480px !important;
          padding-top: 40px;
          padding: 40px 20px 0px 20px;
          margin: 30px 10px 0px 30px;
          /* background-color:rgb(96, 95, 95); */
          border-radius: 30px;
          height: 100vh;
          overflow-y: auto;
          overflow-x: hidden;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          position: relative;
          z-index: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

        }
        /* on 1100px width the order is 2 for relatedJobs */
        @media (max-width: 1100px) {
          .RelatedJobsContainer {
            order: 2;
            height: 100%;
            
          }
          .singleJobRelatedContainer{
            flex-direction: column;
            align-items: center;

          }
        }

        /* small width for relatedJobsContainer */
        .RelatedJobsContainer::-webkit-scrollbar {
          width:0px;
        }
        .RelatedJobsContainer:hover::-webkit-scrollbar {
          width: 3px;
        }